
























































import Vue from 'vue'
import Component from 'vue-class-component'
import { apiAgentDetail } from '@/api/user';
@Component
export default class AgentDetail extends Vue {
	// 表单数据
	form :any = {};

	// 模拟用户选项
	users = [
		{ value: '张学友', label: '张学友' },
		{ value: '王力宏', label: '王力宏' }
	];

	// 模拟区域选项
	regions = [
		{ value: '湖南省 长沙市', label: '湖南省 长沙市' },
		{ value: '广东省 广州市', label: '广东省 广州市' }
	];

	// 处理头像上传成功
	handleAvatarSuccess(res : any, file : any) {
		this.form.avatar = URL.createObjectURL(file.raw)
	}

	// 头像上传前检查
	beforeAvatarUpload(file : File) {
		const isJPG = file.type === 'image/jpeg'
		const isLt2M = file.size / 1024 / 1024 < 2

		if (!isJPG) {
			this.$message.error('上传头像图片只能是 JPG 格式!')
		}
		if (!isLt2M) {
			this.$message.error('上传头像图片大小不能超过 2MB!')
		}
		return isJPG && isLt2M
	}

	// 提交表单
	onSubmit() {
		console.log('提交数据:', this.form)
	}

	// 返回操作
	onCancel() {
		console.log('返回上一页')
		this.$router.go(-1) // 返回上一页
	}
	created(){
		apiAgentDetail({
			id: this.$route.query.id
		}).then((res)=>{
			this.form = res
			console.log(res);
		})
	}
}
